import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { PageLayout } from 'src/components/Layout/PageLayout';
import { HomeTemplate } from 'src/components/Home/Template';
import { Container } from 'src/components/Container/Container';
import WorkshopPage from './workshop';

interface IHomePageProps {
  data: LS.IMarkdownQuery<LS.IHomePageData>;
}

const HomePage: React.FC<IHomePageProps> = ({ data }) => {
  const pageData = data.markdownRemark.frontmatter;

  return (
    // <PageLayout>
    //   <HomeTemplate
    //     splashImage={pageData.splashImage}
    //     splashTitle={pageData.splashTitle}
    //     splashExampleUrl={pageData.splashExampleUrl}
    //     clients={pageData.clients}
    //     getStarted={pageData.getStarted}
    //     pricing={pageData.pricing}
    //     info={pageData.info}
    //   />
    // </PageLayout> TODO: uncomment and remove what's below when site is ready
    <WorkshopPage />
  );
};

export const pageQuery = graphql`
  query($templateKey: String!) {
    markdownRemark(frontmatter: { templateKey: { eq: $templateKey } }) {
      frontmatter {
        splashImage {
          childImageSharp {
            fluid(maxWidth: 2560, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
        splashTitle
        splashExampleUrl
        clients {
          logoUrl {
            childImageSharp {
              fluid(maxWidth: 200, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
          logoTitle
        }
        getStarted {
          title
          subTitle
          text
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
          imageTitle
        }
        pricing {
          title
          subTitle
        }
        info {
          title
          items {
            image {
              childImageSharp {
                fluid(maxWidth: 60, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
            title
            text
          }
        }
      }
    }
  }
`;

export default HomePage;
